import React, { useState, createContext, useEffect } from 'react'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'
import { Theme } from '@material-ui/core/styles'
import { theme as getThemed } from 'get-theme'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeOptions } from '@material-ui/core'
import { getValue } from 'firebase-utils'
const _ = require('lodash'); // eslint-disable-line

/**
 *
 */
export const ThemeContext = createContext({})

/**
 *
 */

interface ThemeOverride extends Theme {
    isPdcTheme: boolean
}
/**
 *
 */
export interface ThemeProviderProps {
    /**
     * theme being passed down for app being used, should not need to use
     */
    theme?: ThemeOverride
    /**
     * dom children in jsx. do not need to use. wrap your components
     */
    children: React.ReactNode

    /**
     * Allows you to override any or all theme options specifically for this theme provider,
     * this options are merged in before any overrides due to remote config.
     */
    themeOverrides?: ThemeOptions
}

/**
 * Establishes a theme context used by various components throughout part (or all) of an app.
 * This merges in any theme overrides from remote config or props.themeOverrides.
 * The theme context influences the styles applied to components such as typography settings, palettes, etc.
 */
export const ThemeProvider = (props: ThemeProviderProps): React.ReactElement => {
    const defaultTheme = props.theme?.isPdcTheme ? props.theme : getThemed

    const themeOverrides = props.themeOverrides || {}
    const [remoteConfigOverrides, setRemoteConfigOverrides] = useState('{}')

    const mergeWithDefaultTheme = (themeOptions: ThemeOptions[]) => {
        // Merge all theme options (in a sensible order) to compute the effective theme
        let mergedThemeOptions = JSON.parse(JSON.stringify(defaultTheme))
        themeOptions.forEach(options => {
            mergedThemeOptions = _.merge(mergedThemeOptions, options)
        })

        return createMuiTheme(mergedThemeOptions)
    }

    const [theme, setTheme] = useState(mergeWithDefaultTheme([themeOverrides, JSON.parse(remoteConfigOverrides)]))

    useEffect(() => {
        getValue('branded_theme_override', setRemoteConfigOverrides)
    }, [])

    useEffect(() => {
        const mergedTheme = mergeWithDefaultTheme([themeOverrides, JSON.parse(remoteConfigOverrides)])
        if (mergedTheme !== theme && mergedTheme !== props.theme) setTheme(mergedTheme)
    }, [remoteConfigOverrides, JSON.stringify(themeOverrides)])

    return (
        <ThemeContext.Provider value={theme}>
            <MuiThemeProvider theme={theme}>
                {props.children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

/**
 * get-theme wrapper
 */
export function getTheme () : any { return getThemed }
