import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5707%3A5671
 *
 * @param {SvgIconProps} props - svg props
 */
export const DownIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.86198 13.3088C5.56932 13.5987 5.56531 14.0705 5.853 14.3653L11.4639 20.1164C11.7583 20.4181 12.2433 20.4181 12.5376 20.1164L18.147 14.3653C18.4346 14.0705 18.4306 13.5988 18.1379 13.3089L17.3474 12.5256C17.054 12.235 16.5809 12.2362 16.289 12.5283L12.9523 15.8672V4.75C12.9523 4.33579 12.6165 4 12.2023 4H11.7975C11.3833 4 11.0475 4.33579 11.0475 4.75V15.8672L7.71083 12.5283C7.41893 12.2362 6.94583 12.235 6.65247 12.5257L5.86198 13.3088Z' fill={color}/>
        </SvgIcon>
    )
}

export default DownIcon
