/* eslint-disable @typescript-eslint/naming-convention */
import versions from './src/versions'
import StyleInterface from './src/intefaces/Style.interface'

const defaultVersion = 'v1'
const defaultTheme = 'main'
const defaultMode = 'light'

/**
 *
 */
const generateThemeVersion = (version, theme, mode) => {
    const { colors, typography } = versions[version][theme][mode]
    const styles: StyleInterface = {
        _data: {
            mode,
            version,
            theme
        },
        colors,
        typography
    }

    return styles
}

/**
 *
 */
export { generateThemeVersion }

const defaultStyle = generateThemeVersion(defaultVersion, defaultTheme, defaultMode)

export default defaultStyle
