import { theme } from 'get-theme'

/**
 * Picks a color based on last 2 digits of number
 *
 * @param {number} number
 * @returns {string} the choosen color
 */
export function getColorByNumber (number) {
    const colorIndex = parseInt(number, 10) % theme.messagesApp.profileImageColorList.length
    // had issues where theme was not always avaible through context imported directly
    return theme.messagesApp.profileImageColorList[colorIndex]
}

/**
 * Picks a color based on last 2 digits of number
 *
 * @param {number} numbers
 * @returns {string} the choosen color
 */
export function iconBackgroundStyle (numbers) {
    if (numbers.length === 0) { return { backgroundColor: getColorByNumber(0) } }
    if (numbers.length === 1) { return { backgroundColor: getColorByNumber(numbers[0]) } } else {
        return { background: theme.messagesApp.profileGroupImageColor }
    }
}

/**
 * Brightens the given color by the specified co-efficient
 *
 * @param {string} color
 * @param {number} coefficient
 * @returns {string} the modified (brightened) color
 */
export function lighten (color, coefficient) {
    color = decomposeColor(color)
    coefficient = clamp(coefficient)

    if (color.type.indexOf('hsl') !== -1) {
        color.values[2] += (100 - color.values[2]) * coefficient
    } else if (color.type.indexOf('rgb') !== -1) {
        for (let i = 0; i < 3; i += 1) {
            color.values[i] += (255 - color.values[i]) * coefficient
        }
    }

    return recomposeColor(color)
}

/**
 * Darkens the given color by the specified co-efficient
 *
 * @param {string} color
 * @param {number} coefficient
 * @returns {string} the modified (darkened) color
 */
export function darken (color, coefficient) {
    color = decomposeColor(color)
    coefficient = clamp(coefficient)

    if (color.type.indexOf('hsl') !== -1) {
        color.values[2] *= 1 - coefficient
    } else if (color.type.indexOf('rgb') !== -1) {
        for (let i = 0; i < 3; i += 1) {
            color.values[i] *= 1 - coefficient
        }
    }

    return recomposeColor(color)
}

/**
 * Decomposes the given color into its type and associated values.
 *
 * @param {string} color
 * @returns
 */
export function decomposeColor (color) {
    if (color.type) return color

    if (color.charAt(0) === '#') {
        return decomposeColor(hexToRgb(color))
    }

    const marker = color.indexOf('(')
    const type = color.substring(0, marker)

    const suppportedFormats = '#nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()'
    if (!['rgb', 'rgba', 'hsl', 'hsla'].includes(type)) {
        throw new Error(`Unsupported color format: ${color}. Must be in one of the following formats: ${suppportedFormats}`)
    }

    let values = color.substring(marker + 1, color.length - 1).split(',')
    values = values.map(function (value) {
        return parseFloat(value)
    })
    return {
        type: type,
        values: values
    }
}

/**
 * Re-composes a color based on its type and associated values.
 *
 * @param {string} color
 * @returns {string} the recomposed CSS color value
 */
export function recomposeColor (color) {
    const type = color.type
    let values = color.values

    if (type.indexOf('rgb') !== -1) {
        values = values.map((n, i) => i < 3 ? parseInt(n, 10) : n) // Convert everything but the alpha component
    } else if (type.indexOf('hsl') !== -1) {
        values[1] = ''.concat(values[1], '%')
        values[2] = ''.concat(values[2], '%')
    }

    return ''.concat(type, '(').concat(values.join(', '), ')')
}

/**
 * Converts a hexidemical representation of a color to a "rgb(...)" string.
 *
 * @param {string} color
 * @returns {string} the rgb() string
 */
export function hexToRgb (color) {
    color = color.substr(1)
    const re = new RegExp('.{1,'.concat(color.length / 3, '}'), 'g')
    let colors = color.match(re)

    if (colors && colors[0].length === 1) {
        colors = colors.map(function (n) {
            return n + n
        })
    }

    return colors ? 'rgb('.concat(colors.map(n => parseInt(n, 16)).join(', '), ')') : ''
}

/**
 * Clamps a numeric value ensuring it remains within a min/max range of values.
 *
 * @param {number} value
 * @returns {number} Returns the value clamped between the given min and max values.
 */
function clamp (value) {
    const min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0
    const max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1
    return Math.min(Math.max(min, value), max)
}

export default { getColorByNumber, iconBackgroundStyle }
