/**
 *
 */
const generateVuetifyTheme = (palette, defaultColors) => {
    const defaultPrimaryColor = {
        base: defaultColors.greenBrandStandard,
        lighten1: palette.primary.light,
        lighten3: defaultColors.greenBrandStandard,
        lighten5: '#e6f6e2',
        darken1: palette.primary.dark,
        darken4: palette.primary.textDark
    }

    const defaultVuetifyColors = {
        primary: defaultPrimaryColor,
        secondary: {
            base: palette.secondary.main,
            lighten1: palette.secondary.light,
            lighten2: '#E0E4E6', // secondary/background
            lighten3: '#85929A',
            lighten5: '#85929A',
            darken1: '#6A757D',
            darken4: palette.secondary.textDark
        },
        error: {
            base: defaultColors.alertColor,
            lighten1: palette.error.light,
            lighten3: defaultColors.alertColor,
            lighten5: '#FCDEDA',
            darken1: palette.error.dark,
            darken4: palette.error.textDark
        },
        info: {
            base: palette.info.main,
            lighten1: palette.info.light,
            lighten3: palette.info.main,
            lighten5: '#E1F1F5',
            darken1: palette.info.dark,
            darken4: palette.info.textDark
        },
        success: defaultPrimaryColor,
        warning: {
            base: palette.warning.main,
            lighten1: palette.warning.light,
            lighten3: '#FF6D20',
            lighten5: '#FF6D20',
            darken1: '#DB5706',
            darken4: '#BD4403'
        },
        action: {
            base: '#85929A',
            lighten1: '#EEF0F1', // disabledText
            lighten2: '#F3F4F5', // disabledBg
            lighten3: '#E7F7E3', // primaryFocus
            lighten4: '#E4E7E9', // secondaryFocus
            lighten5: '#D0E8EF', // infoFocus
            darken1: '#FDE3D3', // warningFocusFocus
            darken2: '#FCE3E1' // errorFocusFocus
        },
        text: {
            base: defaultColors.grayDark, // primary
            lighten1: palette.text.secondary, // secondary
            lighten2: palette.text.tertiary, // tertiary
            lighten3: '#AFB3B5' // textDisabled
        }
    }

    return {
        default: {
            light: defaultVuetifyColors
        },
        avo: {
            light: {
                ...defaultVuetifyColors,
                primary: '#248085',
                info: '#9499A1'
            }
        },
        idseal: {
            light: {
                ...defaultVuetifyColors
            }
        },
        tailor: {
            light: {
                ...defaultVuetifyColors,
                primary: '#FB6C84',
                secondary: '#CBCCD3',
                info: '#1F90F8'
                // secondary: '#CBCCD3',
            }
        },
        ringboost: {
            light: {
                ...defaultVuetifyColors,
                primary: '#205DA0',
                info: '#7CBC54'
            }
        },
        pfm: {
            light: {
                ...defaultVuetifyColors,
                primary: '#F7297F',
                info: '#24272C'
            }
        }
    }
}

export default generateVuetifyTheme
