import React, { useEffect, useState } from 'react'
import AreaCodeFilter from './search-filters/area-code-filter/AreaCodeFilter'
import NumberTypeFilter, { NumberTypeMap } from './search-filters/number-type-filter/NumberTypeFilter'
import KeywordFilter from './search-filters/keyword-filter/KeywordFilter'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import * as API from './API'

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 0,
        padding: '10px'
    },
    grid: {
        maxWidth: '464px',
        width: '100%'
    }
}))

/**
 * Configuration applicable to all types of filters
 */
export interface FilterOptions {
    name: 'AreaCode' | 'NumberType' | 'KeywordSearch'
    size: number
}

/**
 * Represents a given set of values that can be used to
 * perform a number picker search. This format is independent of the
 * filterBy / request payload used by the number search APIs.
 */
export interface FilterValues {
    numberTypesEnabled: NumberTypeMap;
    selectedAreaCode: string | null;
    keyword: string;
}

interface HeaderProps {
    initialNumberType: string | null;
    initialAreaCode: string | null;
    initialKeyword: string;

    filters: FilterOptions[];
    showResetButton?: boolean;
    fetchNumbers: (filterValues: FilterValues) => void;
    isSmallView: boolean;
}

/**
 * The header to the number picker that contains a row of search filters,
 * and a search bar displayed below the search filters.
 */
const Header = ({ initialNumberType, initialAreaCode, initialKeyword, filters, fetchNumbers, isSmallView }: HeaderProps): JSX.Element => {
    const classes = useStyles({ isSmallView })

    const [numberTypesEnabled, setNumberTypesEnabled] = useState<NumberTypeMap>({
        local: initialNumberType === 'local',
        toll_free: initialNumberType === 'toll-free' // eslint-disable-line @typescript-eslint/naming-convention
    })

    const [loadingAreaCodes, setLoadingAreaCodes] = useState<boolean>(false)
    const [localAreaCodes, setLocalAreaCodes] = useState<string[]>([])
    const tollFreeAreaCodes: string[] = ['800', '833', '844', '855', '866', '877', '888']

    const [selectedAreaCode, setSelectedAreaCode] = useState<string | null>(initialAreaCode)

    const [keyword, setKeyword] = useState<string>(initialKeyword)

    const loadAreaCodes = async () => {
        setLoadingAreaCodes(true)
        const data = await API.fetchAreaCodes()
        setLocalAreaCodes(data)
        setLoadingAreaCodes(false)
    }

    useEffect(() => {
        loadAreaCodes()
    }, [])

    // Note: in theory both local and toll free can be selected,
    // populate the area code filter with the set of area codes that makes sense based on
    // all selected types of numbers.
    let areaCodeOptions: string[] = []
    if (numberTypesEnabled.toll_free) {
        areaCodeOptions = areaCodeOptions.concat(tollFreeAreaCodes)
    }
    if (numberTypesEnabled.local) {
        areaCodeOptions = areaCodeOptions.concat(localAreaCodes)
    }

    // Logic to make sure selectedAreaCode and number type agree
    useEffect(() => {
        if (localAreaCodes.length > 0 && tollFreeAreaCodes.length > 0 && selectedAreaCode) {
            const isValidTollFreeCode = tollFreeAreaCodes.includes(selectedAreaCode)
            const isValidLocalCode = localAreaCodes.includes(selectedAreaCode)

            if (numberTypesEnabled.toll_free) {
                if (!isValidTollFreeCode && isValidLocalCode) {
                    console.warn('pdcNumberPicker: switching to local as the selected area code is not a toll free code, but is a local area code')
                    setNumberTypesEnabled({ toll_free: false, local: true }) // eslint-disable-line @typescript-eslint/naming-convention
                } else if (!isValidTollFreeCode && selectedAreaCode) {
                    console.warn('pdcNumberPicker: clearing selected area code which is neither a valid local area code nor a toll-free code')
                    setSelectedAreaCode(null)
                }
            } else if (numberTypesEnabled.local) {
                if (!isValidLocalCode && isValidTollFreeCode) {
                    console.warn('pdcNumberPicker: switching to toll-free as the selected area code is not a local code, but is a tol-free area code')
                    setNumberTypesEnabled({ local: false, toll_free: true }) // eslint-disable-line @typescript-eslint/naming-convention
                } else if (!isValidLocalCode && selectedAreaCode) {
                    console.warn('pdcNumberPicker: clearing selected area code which is neither a valid local area code nor a toll-free code')
                    setSelectedAreaCode(null)
                }
            }
        }
    }, [selectedAreaCode, localAreaCodes, tollFreeAreaCodes, setNumberTypesEnabled])

    useEffect(() => {
        fetchNumbers({
            numberTypesEnabled,
            selectedAreaCode,
            keyword
        })
    }, [JSON.stringify(numberTypesEnabled), selectedAreaCode, keyword])

    const filterComponents = {
        AreaCode: () => {
            return (
                <AreaCodeFilter
                    loading={loadingAreaCodes}
                    areaCodeOptions={areaCodeOptions}
                    numberTypesEnabled={numberTypesEnabled}
                    setNumberTypesEnabled={setNumberTypesEnabled}
                    selectedAreaCode={selectedAreaCode}
                    onChange={areaCode => setSelectedAreaCode(areaCode)}
                />
            )
        },
        NumberType: () => (
            <NumberTypeFilter
                numberTypesEnabled={numberTypesEnabled}
                onChange={numberTypeMap => {
                    setSelectedAreaCode(null)
                    setNumberTypesEnabled(numberTypeMap)
                }}
            />
        ),
        KeywordSearch: () => (
            <KeywordFilter
                initialKeyword={initialKeyword}
                onChange={newKeyword => setKeyword(newKeyword)}
            />
        )
    }

    return (
        <div className={classes.header}>
            <Grid
                container
                spacing={3}
                className={classes.grid}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {filters.map((filter: FilterOptions) => (
                    <Grid
                        key={filter.name}
                        item
                        {...(isSmallView ? { xs: 12 } : { xs: filter.size || 12 })}
                    >
                        {filterComponents[filter.name](filter)}
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default Header
