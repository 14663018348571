import React from 'react'
import ReactDOM from 'react-dom'
import NumberPicker, { NumberPickerProps } from 'number-picker'
import { ThemeProvider } from 'providers'
import './index.css'
import { lighten } from '@material-ui/core'
import { theme } from 'get-theme'
const _ = require('lodash'); // eslint-disable-line

interface NumberPickerTheme {
    primaryColor: 'string';
    secondaryColor: 'string';
}
interface RenderOptions {
    renderId: string;
    theme?: NumberPickerTheme;
    options?: NumberPickerProps;
}

const getContainer = (renderId: string) => {
    const container = document.getElementById(renderId)
    if (!container) {
        throw new Error(`pdcNumberPicker: can not find container element with ID: ${renderId}`)
    }
    return container
}

const createThemeOverride = (numberPickerTheme: NumberPickerTheme) => {
    const { primaryColor, secondaryColor } = numberPickerTheme

    return {
        palette: {
            primary: {
                main: primaryColor
            },
            info: {
                textDark: secondaryColor
            },
            action: {
                infoFocus: lighten(secondaryColor, 0.8)
            }
        },
        overrides: {
            MuiFormControl: {
                root: {
                    "& [class*='MuiInputBase-root']": {
                        '&.Mui-focused': {
                            '&:not(.Mui-error)': {
                                background: `${lighten(primaryColor, 0.9)} !important`
                            }
                        }
                    }
                }
            },
            MuiInputBase: {
                root: {
                    '&$focused:not($error)': {
                        backgroundColor: `${lighten(primaryColor, 0.9)} !important`
                    }
                }
            },
            MuiFilledInput: {
                root: {
                    '&:hover': {
                        backgroundColor: lighten(primaryColor, 0.9)
                    }
                },
                underline: {
                    '&::after': {
                        borderBottom: `1px solid ${primaryColor} !important`
                    }
                },
                adornedEnd: {
                    '& .adorned-end-x': {
                        '& svg': {
                            color: primaryColor
                        }
                    }
                }
            }
        }
    }
}

// Since were exposing the NumberPicker component as a library, adding
// very helpful error handling that gracefully avoids failing is useful.
const validateNumberPickerOptions = (opts: NumberPickerProps) => {
    const effectiveConfig = opts

    const reportError = (key: string, errorMessage: string) => {
        console.error(`pdcNumberPicker: ${key}: ${errorMessage}`)
        delete effectiveConfig[key] // so that it will ignore the bad value and fallback to the default
    }

    const validKeys = ['type', 'showTypeSelector', 'npa', 'showNpaSelector', 'keyword', 'showKeywordSearch', 'searchOnLoad', 'scrollToNewNumbers', 'onNumberClick', 'resultsQty', 'allowMoreNumbers']
    Object.keys(opts).forEach(givenKey => {
        if (!validKeys.includes(givenKey)) {
            reportError(givenKey, `invalid option, valid options are: ${validKeys}`)
        }
    })

    const booleanKeys = ['showTypeSelector', 'showNpaSelector', 'showKeywordSearch', 'searchOnLoad', 'scrollToNewNumbers', 'allowMoreNumbers']
    booleanKeys.forEach(boolKey => {
        if (opts[boolKey] !== undefined && typeof opts[boolKey] !== 'boolean') {
            reportError(boolKey, `must be given a boolean value (true or false), received: ${typeof opts[boolKey]}: ${opts[boolKey]}`)
        }
    })

    if (opts.type !== undefined) {
        const validTypes = ['local', 'toll-free']
        if (!validTypes.includes(opts.type)) {
            reportError('type', `must be one of: ${validTypes}`)
        }
    }

    if (opts.npa !== undefined && opts.npa !== null) {
        effectiveConfig.npa = String(opts.npa)
        if (effectiveConfig.npa.length !== 3 || !(/^\d+$/).test(effectiveConfig.npa)) {
            reportError('npa', 'must be a numeric value of exactly 3 digits')
        }
    }

    if (opts.keyword !== undefined) {
        effectiveConfig.keyword = opts.keyword.replace(/\W/g, '').substring(0, 7).toUpperCase()
        if (typeof effectiveConfig.keyword !== 'string' || effectiveConfig.keyword.length < 1) {
            reportError('keyword', 'must be a string containing one or more alpha-numeric characters')
        }
    }

    if (opts.resultsQty !== undefined) {
        if (typeof opts.resultsQty !== 'number' || !Number.isInteger(opts.resultsQty) || opts.resultsQty < 1) {
            reportError('resultsQty', `must be an integer value greater than 1, received: ${opts.resultsQty}`)
        }
    }

    if ((effectiveConfig.npa !== undefined || effectiveConfig.keyword !== undefined) && !effectiveConfig.searchOnLoad) {
        // Explanation: if your trying to pre-select values, we should always perform a search
        console.warn('pdcNumberPicker: forcing searchOnLoad to true as a keyword or npa was pre-selected')
        effectiveConfig.searchOnLoad = true
    }

    return effectiveConfig
}

window.pdcNumberPicker = {
    render: (opts: RenderOptions) => {
        const container = getContainer(opts.renderId)

        const numberPickerOptions = validateNumberPickerOptions(opts.options || {})

        ReactDOM.render(
            <ThemeProvider
                theme={theme}
                themeOverrides={opts.theme ? createThemeOverride(opts.theme) : {}}
            >
                <NumberPicker
                    {...numberPickerOptions}
                />
            </ThemeProvider>,
            container
        )
    },
    unmount: (renderId: string) => {
        const container = getContainer(renderId)
        ReactDOM.unmountComponentAtNode(container)
    }
}

// Example usage:  pdcNumberPicker.render({ renderId: 'number-picker' })
