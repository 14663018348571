import { theme } from 'get-theme'
const mainTheme = theme

/**
 * Material ui styles
 *
 * @param {object} t - passed by the theme provider
 */
const styles = (t) => {
    const theme = (t.palette.secondary['-300']) ? t : mainTheme
    return {
        pdcButton: {
            position: 'relative',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'solid 1.5px',
            background: 'transparent',
            letterSpacing: -0.1,
            textAlign: 'center',
            fontFamily: 'NTR',
            borderWidth: 2,
            borderRadius: 25,
            cursor: 'pointer',
            padding: '13px 28px 11px',
            lineHeight: '16px',
            fontSize: 20,
            minWidth: 100,
            '&:focus': {
                outline: 'none'
            },
            '&:focus-visible': {
                outline: '2px solid black'
            },
            '-webkit-text-stroke-width': '0.3px',
            '&.icon-space-left': {
                paddingLeft: 49
            },
            '&.icon-space-right': {
                paddingRight: 49
            },
            '&.outlined-button': {
                '&.disabled': {
                    color: theme.palette.secondary['-300'],
                    background: 'white',
                    borderColor: theme.palette.secondary['-300']
                }
            },
            '&.primary-outlined-button': {
                borderColor: theme.palette.primary['0'],
                color: theme.palette.primary['0'],
                '&:hover': {
                    borderColor: theme.palette.primary['+100'],
                    color: theme.palette.primary['+100'],
                    background: 'white'
                }
            },
            '&.secondary-outlined-button': {
                borderColor: theme.palette.secondary['0'],
                color: theme.palette.secondary['0'],
                '&:hover': {
                    borderColor: theme.palette.secondary['+100'],
                    color: theme.palette.secondary['+100']
                }
            },
            '&.attention-outlined-button': {
                borderColor: theme.palette.attention['0'],
                color: theme.palette.attention['0'],
                '&:hover': {
                    borderColor: theme.palette.attention['+100'],
                    color: theme.palette.attention['+100']
                }
            },
            '&.important-outlined-button': {
                borderColor: theme.palette.important['0'],
                color: theme.palette.important['0'],
                '&:hover': {
                    borderColor: theme.palette.important['+100'],
                    color: theme.palette.important['+100']
                }
            },
            '&.tertiary-outlined-button': {
                borderColor: theme.palette.tertiary['0'],
                color: theme.palette.tertiary['0'],
                '&:hover': {
                    borderColor: theme.palette.tertiary['+100'],
                    color: theme.palette.tertiary['+100'],
                    background: 'white'
                }
            },
            '&.filled-button': {
                borderColor: 'transparent',
                color: 'white',
                '&.disabled': {
                    color: 'white',
                    background: theme.palette.secondary['-300']
                }
            },
            '&.primary-filled-button': {
                background: theme.palette.primary['0'],
                '&:hover': {
                    background: theme.palette.primary['+100']
                }
            },
            '&.secondary-filled-button': {
                background: theme.palette.secondary['0'],
                '&:hover': {
                    background: theme.palette.secondary['+100']
                }
            },
            '&.tertiary-filled-button': {
                background: theme.palette.tertiary['0'],
                '&:hover': {
                    background: theme.palette.tertiary['+100']
                }
            },
            '&.attention-filled-button': {
                background: theme.palette.attention['0'],
                '&:hover': {
                    background: theme.palette.attention['+100']
                }
            },
            '&.important-filled-button': {
                background: theme.palette.important['0'],
                '&:hover': {
                    background: theme.palette.important['+100']
                }
            },
            '&.text-button': {
                border: 'none',
                color: theme.palette.secondary[0],
                background: 'transparent',
                padding: 0,
                minWidth: 'unset',
                '&.disabled': {
                    color: theme.palette.secondary['-300'],
                    '.pdc-button-icon': {
                        color: theme.palette.secondary['-300']
                    }
                },
                '& .pdc-button-icon': {
                    top: 0,
                    left: 0,
                    position: 'relative',
                    transform: 'none',
                    alignItems: 'unset',
                    '&.icon-left': {
                        marginRight: '6px'
                    }
                }
            },
            '&.primary-text-button': {
                color: theme.palette.primary['0'],
                '&:hover': {
                    color: theme.palette.primary['+100']
                },
                '&.disabled': {
                    color: theme.palette.primary['-300'],
                    '& .pdc-button-icon': {
                        color: theme.palette.primary['-300']
                    }

                }
            },
            '&.secondary-text-button': {
                color: theme.palette.secondary['0'],
                '&:hover': {
                    color: theme.palette.secondary['+100']
                },
                '&.disabled': {
                    color: theme.palette.secondary['-300'],
                    '& .pdc-button-icon': {
                        color: theme.palette.secondary['-300']
                    }

                }
            },
            '&.tertiary-text-button': {
                color: theme.palette.tertiary['0'],
                '&:hover': {
                    color: theme.palette.tertiary['+100']
                },
                '&.disabled': {
                    color: theme.palette.tertiary['-300'],
                    '& .pdc-button-icon': {
                        color: theme.palette.tertiary['-300']
                    }

                }
            },
            '&.attention-text-button': {
                color: theme.palette.attention['0'],
                '&:hover': {
                    color: theme.palette.attention['+100']
                },
                '&.disabled': {
                    color: theme.palette.attention['-300'],
                    '& .pdc-button-icon': {
                        color: theme.palette.attention['-300']
                    }

                }
            },
            '&.important-text-button': {
                color: theme.palette.important['0'],
                '&:hover': {
                    color: theme.palette.important['+100']
                },
                '&.disabled': {
                    color: theme.palette.important['-300'],
                    '& .pdc-button-icon': {
                        color: theme.palette.important['-300']
                    }

                }
            },
            '&.graphic-button': {
                minWidth: 44,
                borderColor: 'transparent',
                '&.add-graphic-button': {
                    width: 44,
                    height: 44,
                    '& .pdc-button-icon': {
                        width: 23,
                        height: 23,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                },
                '&.play-graphic-button': {
                    width: 60,
                    height: 60,
                    padding: '19px 19px 19px 24px',
                    borderRadius: 30,
                    '& .pdc-button-icon': {
                        width: 17,
                        height: 22,
                        top: '50%',
                        left: 22,
                        transform: 'translateY(-50%)'
                    }
                },
                '&.back-graphic-button': {
                    width: 45,
                    height: 45,
                    padding: '12px 18px 12px 15px',
                    borderRadius: 30,
                    minWidth: 45,
                    '& .pdc-button-icon': {
                        left: 'unset',
                        width: 12,
                        height: 21
                    }
                },
                '&.primary-graphic-button': {
                    background: theme.palette.primary['-400'],
                    '&:hover': {
                        background: theme.palette.primary['-300']
                    },
                    '& svg': {
                        color: theme.palette.primary['0']
                    }
                },
                '&.secondary-graphic-button': {
                    background: theme.palette.secondary['-400'],
                    '&:hover': {
                        background: theme.palette.secondary['-300']
                    },
                    '& svg': {
                        color: theme.palette.secondary['0']
                    }
                },
                '&.tertiary-graphic-button': {
                    background: theme.palette.tertiary['-400'],
                    '&:hover': {
                        background: theme.palette.tertiary['-300']
                    },
                    '& svg': {
                        color: theme.palette.tertiary['0']
                    }
                },
                '&.attention-graphic-button': {
                    background: theme.palette.attention['-400'],
                    '&:hover': {
                        background: theme.palette.attention['-300']
                    },
                    '& svg': {
                        color: theme.palette.attention['0']
                    }
                },
                '&.disabled': {
                    background: theme.palette.secondary['-400'],
                    '& svg': {
                        color: `${theme.palette.secondary['-200']} !important`
                    }
                },
                '&.circle-graphic-button': {
                    padding: '0px 0px 0px'
                }
            },
            '& .pdc-button-icon': {
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 20,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                '& > svg': {
                    maxHeight: '100%',
                    maxWidth: '100%'
                },
                '&.rotate': {
                    transform: 'translateY(-50%) rotate(180deg)'
                },
                '&.icon-left': {
                    left: 20
                },
                '&.icon-right': {
                    right: 20
                }
            }
        }
    }
}

export default styles
