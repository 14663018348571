const defaultShade = 500

const colors = {
    neutral: {
        50: '#F2F1F1',
        100: '#D9D8D8',
        200: '#BFBEBE',
        300: '#A6A6A6',
        400: '#8C8C8C',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#262626',
        900: '#1A1A1A'
    },
    primary: {
        50: '#D7EBD3',
        100: '#AADBA0',
        200: '#81CC72',
        300: '#5EBD4B',
        400: '#3FAD2A',
        500: '#30991C',
        600: '#1B8008',
        700: '#106100',
        800: '#0B3D00',
        900: '#041A00'
    },
    secondary: {
        50: '#D3EBE6',
        100: '#A0DBCF',
        200: '#72CCBA',
        300: '#4BBDA6',
        400: '#2AAD93',
        500: '#1C9980',
        600: '#088068',
        700: '#00614E',
        800: '#003D31',
        900: '#001A15'
    },
    tertiary: {
        50: '#E5DBD8',
        100: '#D4C3BE',
        200: '#C4AEA7',
        300: '#B89D95',
        400: '#A68E86',
        500: '#947B73',
        600: '#7A645D',
        700: '#634E48',
        800: '#473631',
        900: '#2B1F1B'
    },
    destructive: {
        50: '#FFE2DD',
        100: '#FEBCB2',
        200: '#FE9D8E',
        300: '#FD7B67',
        400: '#FD583F',
        500: '#F5472C',
        600: '#DB361D',
        700: '#B7230C',
        800: '#8D1E0D',
        900: '#601307'
    }
}

for (const key of Object.keys(colors)) {
    colors[key].base = colors[key][defaultShade]
}

/**
 *
 */
export { defaultShade }

export default colors
