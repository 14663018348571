import axios from 'axios'
import { PhoneNumberData } from 'phone-number'

interface FilterByOptions {
    area_code?: string; // eslint-disable-line @typescript-eslint/naming-convention
    vanity?: boolean;
    toll_free?: boolean; // eslint-disable-line @typescript-eslint/naming-convention
    local?: boolean;
    contains?: string;
    is_like?: string; // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 * Format of server response for search-available-number API
 */
export interface SearchAvailableNumbersResponse {
    total: number;
    numbers: PhoneNumberData[];
    filter_by: FilterByOptions; // eslint-disable-line @typescript-eslint/naming-convention
    limit?: number;
}

/**
 * Fetches numbers matching the given filter-by values.
 */
export const searchAvailableNumbers = async (requestPayload, cancelToken): Promise<SearchAvailableNumbersResponse> => {
    return axios.post(
        `https://${process.env.REACT_APP_SERVICES_HOST}/numbers/search-available-numbers`,
        requestPayload,
        { cancelToken: cancelToken.token }
    ).then(response => response.data)
}

/**
 * Fetches listings of all toll-free and local area codes that can be used to search for
 * PDC numbers.
 */
export const fetchAreaCodes = async (): Promise<string[]> => {
    return await axios.post(`https://${process.env.REACT_APP_API_HOST}/sign-up/steps/select-area-code`).then(response => response.data.area_codes)
}
