export default {
    h1: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '60px',
        lineHeight: '70px',
        letterSpacing: '-1px'
    },
    h2: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '44px',
        lineHeight: '52px',
        letterSpacing: '-1.5px'
    },
    h3: {
        fontFamily: 'Inter',
        fontSize: '36px',
        fontWeight: 600,
        lineHeight: '44px',
        letterSpacing: '-0.3px'
    },
    h4: {
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '-0.3px'
    },
    h5: {
        fontFamily: 'Inter',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '34px',
        letterSpacing: '-0.2px'
    },
    h6: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '28px',
        letterSpacing: '-0.2px'
    },
    h7: {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '26px',
        letterSpacing: '-0.2px'
    },
    h8: {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '-0.2px'
    },
    subtitle1: {
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '23px',
        letterSpacing: '-0.1px'
    },
    subtitle2: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '21px'
    },
    subtitle3: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px'
    },
    body1: {
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '23px'
    },
    body1heavy: {
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: 700,
        lineHeight: '23px'
    },
    body2: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '-0.1px'
    },
    body2heavy: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: '21px'
    },
    body3: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px'
    },
    body3heavy: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '0.1px'
    },
    body4: {
        fontFamily: 'Inter',
        fontSize: '10.5px',
        fontWeight: 500,
        lineHeight: '16px'
    },
    body4heavy: {
        fontFamily: 'Inter',
        fontSize: '10.5px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.2px'
    },
    buttonLarge: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '-0.1px'
    },
    buttonMedium: {
        fontFamily: 'Inter',
        fontSize: '12.5px',
        fontWeight: 600,
        lineHeight: '15px'
    },
    buttonSmall: {
        fontFamily: 'Inter',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '16px'
    },
    captionLight: {
        fontFamily: 'Inter',
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '16px'
    },
    captionHeavy: {
        fontFamily: 'Inter',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '16px'
    },
    overline: {
        fontFamily: 'Inter',
        fontSize: '10.5px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.4px'
    },
    serial: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '12px',
        letterSpacing: '1px'
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '12px',
        letterSpacing: '0.1px'
    },
    helperText: {
        fontFamily: 'Inter',
        fontSize: '10.5px',
        fontWeight: 500,
        lineHeight: '14px'
    },
    avatarXlarge: {
        fontFamily: 'Inter',
        fontSize: '56px',
        fontWeight: 500,
        lineHeight: '56px',
        letterSpacing: -'2px'
    },
    avatarLarge: {
        fontFamily: 'Inter',
        fontSize: '36px',
        fontWeight: 500,
        lineHeight: '36px',
        letterSpacing: -'1px'
    },
    avatarMedium: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '-0.5px'
    },
    avatarSmall: {
        fontFamily: 'Inter',
        fontSize: '17px',
        fontWeight: 500,
        lineHeight: '17px',
        letterSpacing: '-0.4px'
    }
}
