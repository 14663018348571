import React, { useEffect, useState, useRef } from 'react'
import PhoneNumber, { PhoneNumberData } from '../phone-number/PhoneNumber'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import { DownIcon } from 'svg-icons/src'
import { NumberPickerNumberData } from './NumberPicker'
import { lighten, darken } from 'colors'

const useStyles = makeStyles(theme => ({
    oneTimeFeeNotice: {
        marginTop: '10px',
        color: darken(theme.palette.primary.main, 0.2),
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
        padding: '10px 15px',
        display: 'inline-block'
    },
    numberList: {
        display: 'flex',
        flexDirection: ({ isSmallView }) => isSmallView ? 'column' : 'row',
        justifyContent: ({ isSmallView }) => isSmallView ? 'stretch' : 'center',
        flexWrap: ({ isSmallView }) => isSmallView ? 'nowrap' : 'wrap',
        gap: '20px',
        margin: '20px 0',
        width: ({ isSmallView }) => isSmallView ? '90%' : 'unset',
        maxWidth: ({ isSmallView }) => isSmallView ? 'unset' : '440px'
    },
    moreNumbersButton: {
        marginTop: '25px',
        marginBottom: '20px',
        background: 'none',
        border: 'none',
        padding: '0 5px',
        outline: 'inherit',
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
        '& span': {
            alignItems: 'center'
        },
        '&:focus': {
            outline: `1px dotted ${theme.palette.primary.main}`
        }
    }
}))

interface NumberListProps {
    phoneNumbers: PhoneNumberData[];
    pageSize: number;
    allowMoreNumbers: boolean;
    isSmallView: boolean;
    onNumberClick?: (numberData: NumberPickerNumberData) => void;
    scrollToNewNumbers?: boolean;
}

/**
 * Displays a listing of numbers, organized into pages. The user can choose to reveal the next
 * page of numbers by clicking "More numbers".
 */
const NumberList = ({ phoneNumbers, pageSize, allowMoreNumbers, isSmallView, onNumberClick, scrollToNewNumbers }: NumberListProps): JSX.Element => {
    const classes = useStyles({ isSmallView })

    const numberListRef = useRef(null)

    const [revealedNumberCount, setRevealedNumberCount] = useState<number>(pageSize)
    const [numberToFocus, setNumberToFocus] = useState<null | string>(phoneNumbers.length ? phoneNumbers[0].phone_number : null)

    const componentMounted = useRef<boolean>(false)

    const showMoreNumbers = event => {
        event.preventDefault()
        const number = phoneNumbers[revealedNumberCount]
        setNumberToFocus(number.phone_number)
        setRevealedNumberCount(revealedNumberCount + pageSize)
    }

    useEffect(() => {
        if (!componentMounted.current) {
            componentMounted.current = true
            return // Intentionally skip below focus/scrolling logic which we dont want on mount
        }

        if (!numberListRef.current) {
            return
        }

        const numberButtonToFocus = numberListRef.current.querySelector(`[data-testid="${numberToFocus}"]`)
        if (scrollToNewNumbers && numberButtonToFocus) {
            numberButtonToFocus.focus()
            numberButtonToFocus.scrollIntoView({ behavior: 'smooth' })
        }
    }, [numberToFocus])

    return (
        <>
            <Typography
                variant="body1"
                color="primary"
                className={classes.oneTimeFeeNotice}
                remoteConfigID="number_picker_one_time_fees"
            />

            <div ref={numberListRef} className={classes.numberList}>
                {phoneNumbers.slice(0, revealedNumberCount).map((phoneNumber: PhoneNumberData, index) => (
                    <PhoneNumber
                        testId={phoneNumber.phone_number}
                        key={index}
                        phoneNumber={phoneNumber}
                        isSmallView={isSmallView}
                        onNumberClick={(p: PhoneNumberData) => onNumberClick?.({
                            number_search_result_id: p.number_search_result_id, // eslint-disable-line @typescript-eslint/naming-convention
                            phone_number: p.phone_number, // eslint-disable-line @typescript-eslint/naming-convention
                            format_mask: p.format_mask, // eslint-disable-line @typescript-eslint/naming-convention
                            price: p.price * 100
                        })}
                    />
                ))}
            </div>

            {allowMoreNumbers && (revealedNumberCount < phoneNumbers.length) &&
                <button onClick={showMoreNumbers} className={classes.moreNumbersButton}>
                    <Typography variant="body1" display="flex">
                        More numbers <DownIcon />
                    </Typography>
                </button>
            }
        </>
    )
}

export default NumberList
