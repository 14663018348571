import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'

const useStyles = makeStyles(theme => ({
    noResults: {
        padding: '10px'
    }
}))

/**
 * UI displayed when there are exactly zero numbers returned by a number search.
 */
const NoResults = (): JSX.Element => {
    const classes = useStyles()

    return (
        <div className={classes.noResults}>
            <Typography
                variant="h6"
                color="textPrimary"
                align="center"
                remoteConfigID="number_picker_no_results_heading"
            />
            <Typography
                variant="body2"
                color="textPrimary"
                align="center"
                remoteConfigID="number_picker_no_results_description"
            />
        </div>
    )
}

export default NoResults
